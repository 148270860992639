import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/cases-apps-text.css" //解决方案的全局样式
import 'reset-css'
import VueAMap from 'vue-amap'

import {
  Divider, Carousel,
  CarouselItem, Message
} from 'element-ui'; // 按需引入 element ui
Vue.use(Divider)
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.prototype.$message = Message;

Vue.config.productionTip = false
Vue.use(VueAMap)
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  key: 'a2981f7a842406620d2b3667153cfbb7',
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4'
})

Vue.prototype.gotoAbout = function () {
  router.push("/about")
}
// 定义一个过滤器 处理时间
Vue.filter('dateFormat', function (originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  // 月份从0开始,使她变成字符串,不足两位时,前面补个0.
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  // const hh = (dt.getHours() + '').padStart(2, '0')
  // const mm = (dt.getMinutes() + '').padStart(2, '0')
  // const ss = (dt.getSeconds() + '').padStart(2, '0')
  return `${y}-${m}-${d}`
})

//过滤器 去除空格
Vue.filter('myTrim', function (value) {
  if (value) {
    value = value.replace(/\s/g, "")
  }
  return value

})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);//路由跳转定位到顶部
})