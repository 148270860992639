<template>

  <div class="bottom">
    <div class="bottom-box">

      <div class="about-us">

        <div class="logo-box">
          <img class="baosheng" src="@/assets/images/home/bottom-logo.png" />
          <img class="weixin" src="@/assets/images/home/weixin.png" />
        </div>

        <div class="info">电话：020-88902717/020-88902718</div>
        <div class="info">邮箱：marketing@bioitbs.com</div>
        <div class="info">地址：广州市天河区五山路科华街251号A2栋6018单元</div>
      </div>

      <div class="product">
        <div class="product-title">产品与服务</div>
        <div class="product-item" v-for="(item, index) in productData" :key="index" @click="goToSubhead(item.url)">{{
            item.title
        }}</div>
      </div>

      <div class="projects">
        <div class="projects-title">解决方案</div>
        <div class="projects-item-box">
          <div class="projects-item" v-for="(item, index) in projectsData" :key="index" @click="goToSubhead(item.url)">
            {{
                item.title
            }}</div>
        </div>
      </div>

    </div>
    <div class="AQ">
      Copyright&copy;2018 宝晟（广州）信息技术有限公司 All Rights Reserved. 备案号： <a href="https://beian.miit.gov.cn"
        target="_blank">
        粤ICP备18100051号-1</a>

    </div>
    <!-- 回到顶部按钮 -->
    <div class="backToTop" @click="backTop" v-if="showBtn">

    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      productData: [
        {
          title: "定制服务",
          url: "/customDev"
        },
        {
          title: "技术平台",
          url: "/techPlatform"
        },
        {
          title: "实施顾问",
          url: "/workIssue"
        },
        {
          title: "数据服务",
          url: "/dataServices"
        },
      ],
      projectsData: [
        {
          title: "药企样品管理系统",
          url: "/medicineBank"
        },
        {
          title: "基因数据库管理系统",
          url: "/gene"
        },
        {
          title: "科研项目管理系统",
          url: "/science"
        },
        {
          title: "模式动物管理系统",
          url: "/animalBank"
        },
        {
          title: "仪器设备共享管理系统",
          url: "/instrumentShare"
        },
        {
          title: "实验中心一体化信息管理平台",
          url: "/experimentCenter"
        },
        {
          title: "环境样本管理系统",
          url: "/envBank"
        },
        {
          title: "化合物库信息管理系统",
          url: "/compound"
        },
        {
          title: "多中心研究样本与数据管理平台",
          url: "/multicenter"
        },
        {
          title: "种质资源库管理系统",
          url: "/germBank"
        },
        {
          title: "临床研究统计分析平台",
          url: "/clinic"
        },
        {
          title: "医疗机构生物样本库管理系统",
          url: "/bioBank"
        },
      ],
      showBtn: false, // 回到顶部，默认是false，就是隐藏起来
    }
  },
  mounted() {
    window.addEventListener("scroll", this.showbtn, true);
  },
  methods: {
    goToSubhead(url) {
      this.$router.push(url)
    },
    // 回到顶部
    showbtn() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (scrollTop > 900) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },

    /*回到顶部实现过程注解：
        1.获取当前页面距离顶部的距离
        2.计算出每次向上移动的距离，用负的滚动距离除以5，因为滚动的距离是一个正数，想向上移动就是一个减法
        3.用当前距离加上计算出的距离，然后赋值给当前距离，就可以达到向上移动的效果
        4.最后在移动到顶部的时候，清除定时器
        */

    backTop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
  }
}
</script>

<style lang="less" scoped>
.bottom {
  background: #041545;
  color: #fff;
  height: 368px;
  box-sizing: border-box;
  position: relative;

  .bottom-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 54px 0 50px;
    // background: skyblue;
    width: 1366px;
    margin: 0 auto;

    .about-us {
      flex: 1.5;

      .logo-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 292px;
        margin-bottom: 26px;
        position: relative;

        .baosheng {
          width: 209px;
          height: 57px;
        }

        .weixin {

          display: block;
          width: 57px;
          height: 57px;
        }

        &:hover::after {
          position: absolute;
          content: '';
          display: block;
          width: 113px;
          height: 113px;
          background: url("./../assets/images/home/wx-code.png");
          right: -150px;
          top: -22px;
        }

      }

      .info {
        height: 24px;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 24px;
        margin-top: 20px;
        opacity: .5;
      }
    }

    .product {
      flex: 0.5;

      .product-title {
        height: 33px;
        font-size: 23px;
        line-height: 33rpx;
        margin-bottom: 4px;
      }

      .product-item {
        height: 22px;
        font-size: 15px;
        line-height: 22px;
        margin-top: 20px;
        opacity: .5;

        &:hover {
          cursor: pointer;
          color: #4c73dc;
          opacity: 1;
        }
      }
    }

    .projects {
      flex: 2;

      .projects-title {
        height: 33px;
        list-style: 33px;
        font-size: 23px;
      }

      .projects-item-box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .projects-item {
          flex: 1;
          height: 22px;
          font-size: 15px;
          line-height: 22px;
          width: calc((100% - 10px) / 3); // 这里的10px = (分布个数3-1)*间隙5px, 可以根据实际的分布个数和间隙区调整
          min-width: calc((100% - 10px) / 3); // 加入这两个后每个item的宽度就生效了
          max-width: calc((100% - 10px) / 3); // 加入这两个后每个item的宽度就生效了
          margin-top: 20px;
          cursor: pointer;
          opacity: .5;

          &:nth-child(3n) {
            // 去除第3n个的margin-right
            margin-right: 0;
            // flex: 1
          }

          &:hover {
            color: #4c73dc;
            opacity: 1;
          }

        }
      }

    }

  }

  .AQ {
    // background: red;
    height: 22px;
    list-style: 22px;
    font-size: 16px;
    width: 1382px;
    border-top: 1px solid;
    border-color: rgba(255, 255, 255, 0.3);
    padding: 21px 0;
    margin: 0 auto;
    text-align: center;
    opacity: .5;

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: #4c73dc;
        text-decoration: underline;
        opacity: 1;
      }
    }
  }

  .backToTop {
    position: fixed;
    width: 55px;
    height: 55px;
    // background-color: red;
    bottom: 79px;
    right: 35px;
    background-image: url("./../assets/images/back-to-top.png");
    background-size: 100%;
    cursor: pointer;
    z-index: 99999999999999;

    &:hover {
      background-image: url("./../assets/images/backToTop-actived.png");
    }
  }

}
</style>